import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import UploadBtn from "../UploadBtn/UploadBtn";
import Modal from "../Modal/Modal";
import ImportExcel from "../ImportExcel/ImportExcel";
import ExportToExcelButton from "../ExportBtn/ExportToExcelButton";
import SettingsBtn from "../SettingsBtn/SettingsBtn";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SearchEvent from "../SearchEvent/SearchEvent";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { FireBaseContext } from "../../Context/FireBase";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

export default function  SubScribersTable({ row, refCollection }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [rows, setrows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [eventData, setEventData] = React.useState(null);
  const { dbID } = useParams();
  const { EventRefrence, SubscribersRefrence } = React.useContext(FireBaseContext);

  const handleUpload = async (selectedFile, element) => {
    const signatureParams = {
      sign64data: selectedFile,
      eventID: element?.eventID,
      nationalId: element?.nationalId,
    };

    const subscriberDocRef =
    element.refId && typeof element.refId === "string" ? doc(SubscribersRefrence, element.refId) : null;

    try {
      const response = await fetch(
        "https://event.orevan.org/api/upload-signature",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(signatureParams),
        }
      );

      const result = await response.json();
      if (result.message == 'Signature uploaded') {
        await updateDoc(subscriberDocRef, {
          signURL: result.url
        });
      }

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isValidBase64 = (str) => {
    // Regular expression to check if the string is base64 encoded
    const base64Regex = /^data:image\/(png|jpeg|jpg);base64,[A-Za-z0-9+/=]+$/;
    return base64Regex.test(str);
  };

  // Function to update Firestore document if data is not valid
  const updateInvalidSignURL = async (sign64data, rowID) => {
    try {
      const subscribersRef = doc(SubscribersRefrence, rowID);
      await updateDoc(subscribersRef, {
        signURL: sign64data,
      });
    } catch (error) {
      console.error(`Error updating signURL for invalid base64 in row ${rowID}:`, error);
    }
  };

  // Trigger the image uploads on button click (or call it elsewhere in your app)
  const uploadAllImages = () => {
    rows.forEach((row, index) => {
      if (row.sign64data && !isValidBase64(row.sign64data) && !row.signURL) {
        updateInvalidSignURL(row.sign64data, row.refId);
      } else if (row.sign64data && row.refId && !row.signURL && isValidBase64(row.sign64data)) {
        // handleImageUpload(row.sign64data, row.ID, index);
        handleUpload(row.sign64data, row);
      }
    });
  };

  React.useEffect(() => {
    const itemRef = doc(EventRefrence, dbID);
    (async () => {
      const datas = await getDoc(itemRef);
      const Result = await datas.data();
      setEventData(Result);
     
    
    })();
  }, [dbID]);

  React.useEffect(() => {
    let rowLength = 0;
    if(rows.length > 0 && rowLength !== rows.length) {
      rowLength = rows.length;
      uploadAllImages();
    }
  }, [rows]);


  function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] == "string" && typeof b[orderBy]) {
      return b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase() ? -1 : 1;
    } else {
      return b[orderBy] < a[orderBy] ? -1 : 1;
    }
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  React.useEffect(() => {
    setrows(row);
  }, [row]);
  // HeadTitles
  const headCells = [
    {
      id: "#",
      numeric: true,
      disablePadding: false,
      label: "",
    },
    // {
    //   id: "id",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "id",
    // },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "tel",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "organization",
      numeric: true,
      disablePadding: false,
      label: "Organization",
    },
    {
      id: "speciality",
      numeric: true,
      disablePadding: false,
      label: "Speciality",
    },
    {
      id: "nationalId",
      numeric: true,
      disablePadding: false,
      label: "National ID",
    },
    {
      id: "city",
      numeric: true,
      disablePadding: false,
      label: "City",
    },
    {
      id: "sign64data",
      numeric: true,
      disablePadding: false,
      label: "Signature",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    EnhancedTableToolbar.propTypes = {
      numSelected: PropTypes.number.isRequired,
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              style={{ fontSize: "16px" }}
              key={headCell.id}
              align={headCell.numeric ? "center" : "center"}
              padding={headCell.disablePadding ? "normal" : "normal"}
              sortDirection={orderBy === headCell.d ? order : false}
              className="p-0 py-2"
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.nationalId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function EnhancedTableToolbar(props) {
    const { numSelected, newSelected, setSelected } = props;
    const [open, setOpen] = React.useState(false);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
      "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
      },
    }));
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Toolbar className=" d-flex justify-content-end mb-4 gap-4 pt-4">
        {numSelected > 0 && (
          <Typography
            sx={{ width: 'auto !important' }}
            color="blue"
            variant="subtitle1"
            component="div"
          >
            {selected.length} selected
          </Typography>
        )}
        <Tooltip title={numSelected < 0 ? "select subscriber" : "change"}>
          <IconButton></IconButton>
        </Tooltip>
        <button
          disabled={numSelected == 0}
          onClick={handleClickOpen}
          className={` text-white d-flex justify-content-center align-items-center p-2 rounded ${numSelected ? "bg-blueColor   " : "bg-secondary opacity-75"
            } `}
        >
          Change Event
        </button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <SearchEvent
              handleClose={handleClose}
              newSelected={newSelected}
              setSelected={setSelected}
            />
          </DialogContent>
        </BootstrapDialog>

        <div className="d-flex  gap-2 align-items-center bg-success rounded">
          <ExportToExcelButton
            event={eventData}
            sheetname="Sheet 1"
            data={rows}
          />
        </div>
        <div className="">
          <ImportExcel />
        </div>
        <Modal className="lg:w-75" />
      </Toolbar>
    );
  }
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const filteredRows = rows.filter((row) =>
    Object.values(row).some(value =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  return (
    <Box sx={{ width: "100%", overflowX: 'auto' }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className="pr-6 flex justify-end pb-4">
          <div>
            <label className="block text-black text-sm font-bold mb-2">
              Search
            </label>
            <TextField
              variant="outlined"
              value={searchQuery}
              className=" rounded-3 w-100"
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
            />
          </div>
          {/* <button onClick={uploadAllImages}>Upload All Images</button> */}
        </div>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />
            {rows.length !== 0 && (
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.nationalId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      onClick={(event) => handleClick(event, row.nationalId)}

                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      // key={row.ID}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      className="align-items-center border-b   odd:bg-white  even:bg-gray-100 "
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(event) => handleClick(event, row.nationalId)}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.name}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.email}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.tel}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.organization}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.specialty}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.nationalId}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-base">
                          {row.city}
                        </span>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="subRowImg  d-flex justify-content-center gap-1 align-items-center "
                      >
                        <UploadBtn id={row.id} info={row.sign64data && "d-none"} element={row} />
                        {row.sign64data && (
                          <img src={row.sign64data} alt="signature" width={"75px"} style={{ objectFit: 'contain' }} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <SettingsBtn
                          row={row}
                          rowId={row.refId}
                          refCollection={refCollection}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div className="my-3">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper >
    </Box >
  );
}
