import TextField from "@mui/material/TextField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FireBaseContext } from "../../Context/FireBase";
import { updateDoc, getDocs, where, query, doc } from "firebase/firestore";
import swal from "sweetalert";
import * as Yup from "yup";
import { MenuItem, Select, FormControl, InputAdornment } from "@mui/material";
import { HelperContext } from "../../Context/HelperContext";

export const UpdateSubscriberForm = ({ user, handleClose }) => {

  const { FormateCost } = useContext(HelperContext);
  const tovOptions = [
    { types: "Registration Fees", value: 0 },
    { types: "Meals", value: 0 },
    { types: "Accommodation", value: 0 },
    { types: "Medical Utilities", value: 0 },
    { types: "CME Hours", value: 0 },
    { types: "Transportation", value: 0 },
    { types: "Flights", value: 0 },
  ];
  // const countryCode = "+966";

  const NewSubScriberInputs = [
    { label: "First Name", type: "text", name: "name" },
    { label: "Last Name", type: "text", name: "LastName" },
    { label: "Email", type: "text", name: "email" },
    { label: "National/iqamaID", type: "text", name: "nationalId" },
    { label: "Phone Number", type: "text", name: "tel" },
    { label: "Speciality", type: "text", name: "specialty" },
    { label: "Organization", type: "text", name: "organization" },
    { label: "License ID", type: "text", name: "licenceId" },
    { label: "Cost Per Delegate", type: "text", name: "CostperDelegate" },
  ];
  // const compareData = (originalData, updatedData) => {
  //   const changes = [];
  //   Object.keys(updatedData).forEach(key => {
  //     if (originalData[key] !== updatedData[key]) {
  //       changes.push({
  //         field: key,
  //         from: originalData[key],
  //         to: updatedData[key]
  //       });
  //     }
  //   });
  //   return changes;
  // };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "First Name must contain only letters")
      .min(3, "Too short")
      .required("Required"),
    LastName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Last Name must contain only letters")
      .min(3, "Too short")
      .required("Required"),
    email: Yup.string().email("Enter valid email").required("Required"),
    nationalId: Yup.string()
      .matches(/^\d{10}$/, "National ID must be 10 digits")
      .required("Required"),
    tel: Yup.string()
      .matches(/^\d{9}$/, "Phone number must be 9 digits")
      .required("Required"),
    specialty: Yup.string().required("Required"),
    organization: Yup.string().required("Required"),
    licenceId: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    // CostperDelegate: Yup.number().required("Required"),
  });
  const { dbID } = useParams();

  const { SubscribersRefrence, EventRefrence } = useContext(FireBaseContext);
  const [selectedTovOptions, setSelectedTovOptions] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  // const [downloadURL, setDownloadUrl] = useState(null);
  const [cities, setCity] = useState([]);

  // const changeedUser = doc(SubscribersRefrence, user.id);

  const setInitialData = async () => {
    try {
      const q = query(
        SubscribersRefrence,
        where("id", "==", user.id),
        where("email", "==", user.email),
        where("nationalId", "==", user.nationalId)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        const data = { ...firstDoc.data() };
        delete data.ID;

        setUpdatedData({
          ...data,
          tel: extractPhoneNumber(data.tel), // Set the only city as the default value
        });
        setSelectedTovOptions([...data.TransferOfValue]);
    
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };
  // const ref = doc(EventRefrence, dbID);
  function extractPhoneNumber(number) {
    const countryCode = "+966";
    if (number.startsWith(countryCode)) {
      return number.slice(countryCode.length);
    }
    return number; // or return null or an empty string if the number doesn't start with +966
  }
  useEffect(() => {
    setInitialData();
  }, [user]);
  useEffect(() => {
    (async () => {
      // const datas = await getDoc(ref);
      // const Result = datas.data();

      const q = query(
        SubscribersRefrence,
        where("id", "==", user.id),
        where("email", "==", user.email),
        where("nationalId", "==", user.nationalId)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        const Result = firstDoc.data();

        // setCity(Result.city);
        setCity((prevCities) => [...prevCities, Result.city]);

        if (Result.city) {
          setInitialData((prevValues) => ({
            ...prevValues,
            city: Result.city,
          }));
        }
      }
    })();
  }, [dbID]);

  // const ImageData = () => {
  //   return downloadURL || updatedData?.image;
  // };

  const addNewSubScriber = async (values) => {
    try {
      swal({
        title: "Are you sure about these changes?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          // Query to find the document based on the user.id
          const q = query(
            SubscribersRefrence,
            where("id", "==", user.id),
            where("email", "==", user.email),
            where("nationalId", "==", user.nationalId)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const firstDoc = querySnapshot.docs[0];
            const docRef = firstDoc.ref; // Get a reference to the document

            // Update the TransferOfValue field
            values.TransferOfValue = selectedTovOptions;

            values.CostperDelegate = selectedTovOptions.reduce(
              (acc, item) => (acc += parseFloat(item.value)),
              0
            );
         

            // Update the document with the new values
            await updateDoc(docRef, values);

            await handleCost();

            handleClose(); // Close the modal or UI element

            swal({ icon: "success" });
          } else {
            swal({
              title: "Error",
              text: "No subscriber found to update.",
              icon: "error",
            });
          }
        }
      });
    } catch (error) {
      swal({
        title: "Error",
        text: "There was an issue updating the subscriber.",
        icon: "error",
      });
    }
  };
  const handleCost = async () => {
    const q = query(SubscribersRefrence, where("eventID", "==", dbID));
    const querySnapshotCost = await getDocs(q);
    const subscribers = querySnapshotCost.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const totalCost = subscribers.reduce(
      (total, subscriber) => total + parseFloat(subscriber.CostperDelegate),
      0
    );

    // Update event cost in Firebase
    const eventRef = doc(EventRefrence, dbID);
    await updateDoc(eventRef, { eventCost: totalCost });
  };

  const checkTxtValue = (value, item) => {
    const found = selectedTovOptions.find(({ types }) => types === item);
    if (found) {
      found.value = value;
    }
  };
  const handleInputChange = (name, value, formValues, setFormValues) => {
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F]/;
    if (arabicRegex.test(value)) {
      value = value.replace(arabicRegex, "");
    }

    if (name === "nationalId" && value.length > 10) {
      value = value.slice(0, 10);
    }

    if (["name", "LastName"].includes(name)) {
      const letterOnlyRegex = /^[A-Za-z\s]*$/;
      if (!letterOnlyRegex.test(value)) {
        return;
      }
    }

    setFormValues({ ...formValues, [name]: value });

    let isAutoCompleted = false;
    if (name === "nationalId" && value.length >= 7) {
      const matchingItem = user.find(
        (item) => String(item.nationalId).toLowerCase() === value.toLowerCase()
      );
      if (matchingItem) {
        isAutoCompleted = true;
        setFormValues({
          ...matchingItem,
          tel: matchingItem.tel.substring(4),
        });
      }
    }
    if (!isAutoCompleted) {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  if (updatedData) {
    return (
      <>
        <h2>Edit SubScriber</h2>
        <Formik
          initialValues={updatedData}
          validationSchema={validationSchema}
          onSubmit={addNewSubScriber}
        >
          {({ setFieldValue, values, setValues }) => (
            <Form className="grid p-3 bg-white rounded gap-2 NewSubScriberForm md:grid-cols-1 ">
              <div className="w-100 grid grid-cols-1 md:grid-cols-2 gap-4 ">
                {NewSubScriberInputs.map((input, index) => (
                  <div key={index} className="w-full">
                    <label className="block text-black text-sm font-bold my-2">
                      {input.label}
                    </label>
                    {input.name === "tel" ? (
                      // Field for "tel" input with MUI's TextField and InputProps
                      <Field
                        name={input.name}
                        type={input.type}
                        component={input.name === "tel" ? TextField : undefined}
                        sx={{
                          m: 1,
                          width: "25ch",
                          marginTop: input.name === "specialty" ? "22px" : "0",
                        }}
                        className="w-100 appearance-none border rounded text-black leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
                        InputProps={
                          input.name === "tel"
                            ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +966 {/* This is your country code */}
                                  </InputAdornment>
                                ),
                              }
                            : undefined
                        }
                        onInput={(e) => {
                          if (input.name === "tel") {
                            e.target.value = e.target.value.slice(0, 9);
                          }
                        }}
                        value={values[input.name]}
                      
                        onChange={(e) =>
                          handleInputChange(
                            "tel",
                            e.target.value,
                            values,
                            setValues
                          )
                        }
                        pattern={input.name === "tel" ? "[0-9]*" : undefined}
                        disabled={input.name == "CostperDelegate"}
                      />
                    ) : (
                      // Default Field for other inputs
                      <Field
                        name={input.name}
                        type={input.type}
                        className="w-100 appearance-none border rounded px-3 text-black leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
                        disabled={input.name === "CostperDelegate"}
                      />
                    )}

                    {/* <Field
                      // as={TextField}
                      name={input.name}
                      type={input.type}
                      sx={{ m: 1, width: "25ch" }}
                      className="w-100 appearance-none border rounded  px-3 text-black leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
                      onInput={(e) => {
                        if (input.name === "tel") {
                          
                          e.target.value = e.target.value.slice(0, 9); 
                        }
                      }}
                      pattern={input.name === "tel" ? "[0-9]*" : undefined} 
                     
                    
                      disabled={input.name == "CostperDelegate"}
                    /> */}
                    <ErrorMessage
                      name={input.name}
                      component="div"
                      className="text-danger"
                    />
                  </div>
                ))}
                <div>
                  <FormControl fullWidth>
                    <label className="block text-black text-sm font-bold my-2">
                      City
                    </label>
                    <Field
                      as={Select}
                      id={"City"}
                      name={"city"}
                      className="rounded-3 w-100"
                      value={values.city}
                      onChange={(e) =>
                        handleInputChange(
                          "city",
                          e.target.value,
                          values,
                          setValues
                        )
                      }
                    >
                      {cities.map((city, cityIndex) => (
                        <MenuItem key={cityIndex} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </div>
              </div>
              <div className="w-100">
                <div className="w-100 mt-2 d-flex justify-content-center align-items-center flex-column">
                  <div
                    style={{ borderBottom: "1px solid black" }}
                    className="mb-3 w-100"
                  >
                    <label className="block text-black text-sm font-bold my-2">
                      Transfer Of Values
                    </label>

                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={tovOptions}
                      getOptionLabel={(option) => option.types}
                      filterSelectedOptions
                      value={selectedTovOptions}
                      onChange={(e, value) => {
                        setSelectedTovOptions(value);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.types === value.types
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label={<b>Transfer Of Values</b>}
                          className="dropDownBorder "
                          name={"TransferOfValue"}
                        />
                      )}
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <ul className="p-0 d-flex flex-wrap  gap-1 w-100 ">
                      {selectedTovOptions?.map((savedObject, index) => (
                        <li
                          key={index}
                          className=" d-flex flex-column p-2 rounded wrappingItems"
                          style={{ width: "40%" }}
                        >
                          <p className="m-0">Tov: {savedObject.types}</p>
                          <TextField
                            onChange={(e) =>
                              checkTxtValue(e.target.value, savedObject.types)
                            }
                            defaultValue={savedObject.value}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <button className="w-25 p-2 m-2 rounded rounded-2 border-0  text-white  bg-blueColor">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    return (
      <div className="w-100 d-flex justify-content-center">
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
      </div>
    );
  }
};

// {NewSubScriberInputs.map((item, index) => (
//   <div className="p-1" key={`${item.label}-${index}`}>
//     {item.type === "select" ? (
//       <FormControl fullWidth>
//         {/* <InputLabel>{item.label}</InputLabel> */}
//         <label className="block text-black text-sm font-bold mb-2">
//           {item.label}
//         </label>
//         <Field
//           as={Select}
//           // label={item.label}
//           id={`${index}_input`}
//           name={item.name}
//           className="w-100"
//           value={values[item.name]}
//           onChange={(e) =>
//             handleInputChange(
//               item.name,
//               e.target.value,
//               values,
//               setValues
//             )
//           }
//         >
//           {cities.map((city, cityIndex) => (
//             <MenuItem key={cityIndex} value={city}>
//               {city}
//             </MenuItem>
//           ))}
//         </Field>
//       </FormControl>
//     ) : (
//       <>
//         <label className="block text-black text-sm font-bold mb-2">
//           {item.label}
//         </label>
//         <Field
//           as={TextField}
//           // label={item.label}
//           id={`${index}_input`}
//           focused
//           type={item.type}
//           className="border form-control border-gray-600"
//           name={item.name}
//           value={values[item.name]}
//           onChange={(e) =>
//             handleInputChange(
//               item.name,
//               e.target.value,
//               values,
//               setValues
//             )
//           }
//           InputProps={{
//             startAdornment: item.name === "tel" && (
//               <InputAdornment position="start">
//                 {countryCode}
//               </InputAdornment>
//             ),
//           }}
//           inputProps={{
//             maxLength: item.name === "tel" ? 9 : undefined, // Limit to 9 numbers for "tel" input
//             pattern: "[0-9]*", // Optional: to enforce only numeric input
//           }}
//         />
//       </>
//     )}
//     <ErrorMessage
//       name={item.name}
//       component="div"
//       className="text-danger my-1"
//     />

//   </div>
// ))}
