import { Formik, Form, Field } from "formik";
import "./StepOneStyle.css";
import { useContext, useEffect, useState } from "react";
import { FireBaseContext } from "../../../Context/FireBase";
import MultipleSelection from "../../MultipleSelection/MultipleSelection";
import ToggleBtn from "../../ToggleBtn/ToggleBtn";
import TimePicker from "../../timePicker/TimePicker";
import { FranchisedropDown } from "./FranchisedropDown";
import Tov from "../../Tov/Tov";
import DatePickerInput from "../../DatePickerInput/DatePickerInput";
import { onSnapshot } from "firebase/firestore";
import { EventCurrencyDropDown } from "./EventCurrency";
export const StepOne = () => {
  const { newEvent, setNewEvent, setdateError, Cities } =
    useContext(FireBaseContext);
  const [amexNumber, setAmexNumber] = useState(false);
  const [CPD, SetCPD] = useState(0);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(
    newEvent.TransferOfValue || []
  );
  const [items, setItem] = useState([]);
  const [formErrors, setFormErrors] = useState({
    EventName: newEvent.EventName ? "" : "",
    eventDate: newEvent.eventDate ? "" : "",
    City: newEvent.city.length !== 0 ? "" : "",
    EndDate: newEvent.endDate ? "" : "",
    TransferOfValue: newEvent.TransferOfValue.length !== 0 ? "" : "",
    P3: newEvent.BeSure ? "" : "",
    PO: newEvent.PO ? "" : "",
    AmexNum: newEvent.AmexNum ? "" : "",
    Franchise: newEvent.Franchise ? "" : "",
    DateEndHours: newEvent.DateEndHours ? "" : "",
    DateFromHours: newEvent.DateFromHours ? "" : "",
  });

  useEffect(() => {
    if (amexNumber) {
      newEvent.PO = "";
    } else {
      newEvent.AmexNum = "";
    }
  }, [amexNumber]);

  const getDatas = (e) => {
    setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
    if (e.target.value) {
      setFormErrors({ ...formErrors, [e.target.name]: "" });
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: "Required" });
    }
  };


  useEffect(() => {
    const TovSum = () => {
      const data = newEvent.TransferOfValue;
      if (data.length) {
        let sum = data.map((item) => item.value);
        let result = sum.reduce((x, y) => parseFloat(x) + parseFloat(y), 0);
        SetCPD(result);
      } else {
        SetCPD(0);
      }
    };

    TovSum();
  }, [selectedOptions, newEvent.TransferOfValue]);

  useEffect(() => {
    setNewEvent({ ...newEvent, CostperDelegate: CPD });
  }, [CPD]);

  const getData = (CollectionType, SetItem) => {
    onSnapshot(CollectionType, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      SetItem(newData[0].data);
    });
  };

  useEffect(() => {
    getData(Cities, setItem);

    // setSelectedOptions(newEvent.TransferOfValue)
    setSelectedOptions(newEvent.TransferOfValue || []);
  }, []);
  const validateDates = () => {
    if (newEvent.eventDate && newEvent.endDate) {
      const startDate = new Date(newEvent.eventDate);
      const endDate = new Date(newEvent.endDate);

      if (endDate < startDate) {
        setdateError(true);
        setFormErrors({ ...formErrors, EndDate: "not valid date" });
      } else {
        setdateError(false);
        setFormErrors({ ...formErrors, EndDate: "" });
      }
    }
  };
  useEffect(() => {
    validateDates();
  }, [newEvent.eventDate, newEvent.endDate]);
  return (
    <div>
      <Formik>
        {() => (
          <Form
            className="row container justify-content-between  "
            style={{ gap: "40px 0" }}
          >
            <div
              className="align-self-end border-0 bg-transparent text-dark "
              onClick={() => setAmexNumber(!amexNumber)}
            >
              <ToggleBtn />
            </div>
            <div className="errorParent grid grid-cols-2 gap-4">
              <div className="">
                <label className="block text-black text-sm font-bold mb-2">
                  Event Name
                </label>
                <input
                  name={"eventName"}
                  type="text"
                  className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline "
                  onChange={getDatas}
                  defaultValue={newEvent.eventName}
                />
                <small className="text-danger errorMsg">
                  {formErrors.eventName}
                </small>
              </div>
              <div className="">
                <label className="block text-black text-sm  mb-2">
                  {amexNumber ? <b>Amex Num </b> : <b>PO</b>}
                </label>
                <input
                  name={amexNumber ? "AmexNum" : "PO"}
                  type="text"
                  className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  onChange={getDatas}
                  defaultValue={amexNumber ? newEvent.AmexNum : newEvent.PO}
                />
                <small className="text-danger errorMsg">{formErrors.PO}</small>
              </div>
            </div>

            <div className="errorParent grid grid-cols-2 gap-4">
              <div className="">
                <label className="block text-black text-sm font-bold mb-2">
                  BeSure
                </label>
                <input
                  name={"BeSure"}
                  type="text"
                  className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  onChange={getDatas}
                  defaultValue={newEvent.BeSure}
                />
                <small className="text-danger errorMsg">
                  {formErrors.BeSure}
                </small>
              </div>
              <div className="">
                <label className="block text-black text-sm font-bold mb-2">
                  City
                </label>
                <MultipleSelection
                  type="city"
                  label="City"
                  list={items}
                  SetError={setFormErrors}
                  formErrors={formErrors}
                />

                <small className="text-danger errorMsg">
                  {formErrors.City}
                </small>
              </div>
            </div>

            <div className="errorParent col-12 col-md-6">
              <FranchisedropDown
                SetError={setFormErrors}
                formErrors={formErrors}
              />
              <small className="text-danger errorMsg ">
                {formErrors.Franchise}
              </small>
            </div>
            <div className="errorParent col-12 col-md-6">
              <EventCurrencyDropDown
                SetError={setFormErrors}
                formErrors={formErrors}
              />
              <small className="text-danger errorMsg">
                {formErrors.EventCurrency}
              </small>
            </div>

            <div className="errorParent  grid ">
              <Tov
                type="TransferOfValue"
                SetError={setFormErrors}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                formErrors={formErrors}
              />
              <small className="text-danger errorMsg">
                {formErrors.TransferOfValue}
              </small>
            </div>
            <div className="errorParent grid ">
              <label className="block text-black text-sm font-bold mb-2">
                Cost Per Delegate
              </label>
              <Field
                name={"CostperDelegate"}
                className="w-100  appearance-none border rounded w-full px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                readOnly
                // value={TovSum()}
                value={CPD}
              />
            </div>
            <div className=" grid grid-cols-2 gap-4">
              <div className=" ">
                <label className="block text-black text-sm font-bold mb-2">
                  From
                </label>

                <div className=" appearance-none border rounded w-full text-black leading-tight focus:outline-none focus:shadow-outline mb-4">
                  <DatePickerInput
                    condition={true}
                    SetError={setFormErrors}
                    formErrors={formErrors}
                  />
                  <small className="text-danger errorMsg">
                    {formErrors.CreatedAt}
                  </small>
                </div>
                <div className=" appearance-none border rounded w-full text-black leading-tight focus:outline-none focus:shadow-outline mb-4  ">
                  <TimePicker
                    condition={true}
                    SetError={setFormErrors}
                    formErrors={formErrors}
                  />
                </div>
                <small className="text-danger errorMsg">
                  {formErrors.DateFromHours}
                </small>
              </div>
              <div className=" ">
                <label className="block text-black text-sm font-bold mb-2">
                  To
                </label>

                <div className=" appearance-none border rounded w-full text-black leading-tight focus:outline-none focus:shadow-outline mb-4">
                  <DatePickerInput
                    condition={false}
                    SetError={setFormErrors}
                    formErrors={formErrors}
                  />
                  <small className="text-danger errorMsg">
                    {formErrors.EndDate}
                  </small>
                </div>
                <div className=" appearance-none border rounded w-full text-black leading-tight focus:outline-none focus:shadow-outline mb-4">
                  <TimePicker
                    condition={false}
                    SetError={setFormErrors}
                    formErrors={formErrors}
                  />
                  <small className="text-danger errorMsg">
                    {formErrors.DateEndHours}
                  </small>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
