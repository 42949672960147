import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useContext, useEffect, useState } from "react";
import { FireBaseContext } from "../../Context/FireBase";
import { onSnapshot } from "firebase/firestore";

const ExportToExcelButton = ({ data, event, sheetname, }) => {
  const { TransferOfValuesRef } = useContext(FireBaseContext);
  const [TOVs, setTOVs] = useState([]);

  useEffect(() => {
    const getTovData = async () => {
      try {
        onSnapshot(TransferOfValuesRef, (snapshot) => {
          const newData = snapshot.docs.map((doc) => doc.id);
          setTOVs(newData);
        });
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    getTovData();
  }, []);

  let returnedTovs = [];

  function stringToSlug(title) {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  const extractData = event && data?.map((item) => {
    TOVs.forEach((tov) => {
      const totalTov = item.TransferOfValue.filter(
        (transfer) => transfer.types === tov
      ).reduce((total, transfer) => total + Number(transfer.value), 0);
      returnedTovs.push({
        type: tov,
        value: totalTov,
      });
    });
  
    const tovValues = {
      "Event Date": event.eventDate,
      "Title/اللقب": "Dr",
      "FirstName/الاسم الاول": item.name,
      "LastName/الاسم الاخير": item.LastName,
      Specialitzation: item.specialty,
      "Other Specialitzation (optional)": "",
      "Professional Classification Number": item.licenceId,
      "National/Resident ID": item.nationalId,
      "Mobile Number / رقم الجوال": item.tel,
      "Email/الايميل": item.email,
      "Form Of Payment ": "cash or cash equivalent",
      "Grant purpose": item.TransferOfValue?.map(
        (transfer) => `${transfer.types} = ${transfer.value}`
      ).join(","),
      "Payment Amount": item.CostperDelegate,
      city: item.city,
      Organization: item.organization,
      Signature: item.signURL
        ? { text: "Signature Link", hyperlink: item.signURL }
        : "",
    };
    returnedTovs.forEach((tov) => {
      tovValues[tov.type] = tov.value;
    });
    
  
    return tovValues;
  });

 

  const exportToExcel = async () => {
    if (TOVs.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(sheetname);
      const headersList = [
        "Title/اللقب",
        "FirstName/الاسم الاول",
        "LastName/الاسم الاخير",
        "Specialitzation",
        "Other Specialitzation (optional)",
        "Professional Classification Number",
        "National/Resident ID",
        "Mobile Number / رقم الجوال",
        "Email/الايميل",
        "Form Of Payment ",
        "city",
        "Organization",
        "Grant purpose",
        ...TOVs,
        "Payment Amount",
        "Signature",
        "Event Date",
      ];

      worksheet.addRow([...headersList]);
      // Set the background color for the entire row (e.g., row 1)
      const rowIndex = 1;
      const row = worksheet.getRow(rowIndex);
      row.eachCell({ includeEmpty: true }, (cell, index) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "80FFFF00" }, // ARGB format for light red color
        };
        cell.font = { size: 12 };
        cell.alignment = { horizontal: "center", vertical: "middle" };

        if (typeof cell.value === "number") {
          cell.numFmt = "0"; // Display as integer, you can customize this format
        }
      });

      // Add data rows
      extractData?.map((rowItem, index) => {
        const holder = [
          ...headersList?.map((head) => {
            const convertItem = isNaN(Number(rowItem[head]))
              ? rowItem[head]
              : Number(rowItem[head]);
            return rowItem[head] ? convertItem : "";
          }),
        ];

        worksheet.addRow([...holder]);

        const rowIndex = index + 2;
        const row = worksheet.getRow(rowIndex);
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.fill = rowItem.hasOwnProperty("Event Name") && {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFADD8E6" }, // ARGB format for light blue color
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
          if (typeof cell.value === "number") {
            cell.numFmt = "0"; // Display as integer, you can customize this format
          }
        });
      });
      // Set column widths to fit the content
      worksheet.columns.forEach((column, colIndex) => {
        let maxLength = 0;

        // Find the maximum content length in the column
        worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
          const cellValue = row.getCell(colIndex + 1).text;
          maxLength = Math.max(maxLength, cellValue ? cellValue.length : 0);
        });

        // Set the column width based on the maximum content length
        column.width = maxLength + 5; // Add some extra padding
      });
      worksheet.getRow(1).height = 20;
      //   const largeNumberColumn = worksheet.getColumn('K');
      // largeNumberColumn.numFmt = '0';
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, stringToSlug(event.eventName) + "-subscribers");
    }
  };

  return (
    <button onClick={exportToExcel} className=" d-flex gap-2 p-2">
      <i className="fa-solid fa-file-arrow-down fs-4 text-white"></i>
      <span className="text-base text-white "> REPORT</span>
    </button>
  );
};

export default ExportToExcelButton;

// const extractData = data?.map((item) => ({
//   "Event Date": item.eventDate,
//   "Title/اللقب": "Dr",
//   "FirstName/الاسم الاول": item.name,
//   "LastName/الاسم الاخير": item.LastName,
//   Specialitzation: item.specialty,
//   "Other Specialitzation (optional)": "",
//   "Professional Classification Number": item.licenceId,
//   "National/Resident ID": item.nationalId,
//   "Mobile Number / رقم الجوال": item.tel,
//   "Email/الايميل": item.email,
//   "Form Of Payment ": "cash or cash equalivant",
//   "Grant purpose": item.TransferOfValue?.map(
//     (item) => `${item.types} = ${item.value}`
//   ).join(","),
//   "Payment Amount": item.CostperDelegate,
//   city: item.city,
//   Organization: item.organization,
//   Signature: item.signURL
//     ? { text: "Signature Link", hyperlink: item.signURL }
//     : "",
// }));
