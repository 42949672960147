import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FireBaseContext } from "../../Context/FireBase";
import Multiselect from "multiselect-react-dropdown";
import { ChromePicker } from "react-color";
import swal from "sweetalert";
import { doc, onSnapshot, updateDoc, getDoc } from "firebase/firestore";
import DatePicker from "react-datepicker";

function EditEvent() {
  const { dbID } = useParams();
  const { EventRefrence, Cities, UserRef, currentUserRole, currentUsr } =
    useContext(FireBaseContext);

  const [ResultData, setResultData] = useState("");
  const [createdBy, setCreatedBy] = useState({});
  const [checkAuth, setCheckAuth] = useState(false);

  const [selectedColor, setSelectedColor] = useState();
  const [selectedFont, setSelectedFont] = useState();
  const [selectedBtn, setSelectedBtn] = useState();

  const [showBgPicker, setShowBgPicker] = useState(false);
  const [showFontPicker, setShowFontPicker] = useState(false);
  const [showBtnPicker, setShowBtnPicker] = useState(false);

  const [options, setOptions] = useState([]);
  const [valueOption, setValueOption] = useState([]);
  const [items, setItem] = useState([]);

  const bgPickerRef = useRef();
  const fontPickerRef = useRef();
  const btnPickerRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setResultData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function convertStartDateWithYearFormat(dateString) {
    let date = `${dateString}`;
    const [day, month, year] = date.split("/");
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    // return `${year}-${month}-${day}`;
  }

  function convertStartDateWithDayFormat(dateString) {
    const dateDay = `${dateString}`;
    const [year, month, day] = dateDay.split("-");
    return `${day}/${month}/${year}`;
  }

  const handleDateChange = (e) => {
    const formattedDate = convertStartDateWithDayFormat(e.target.value);
    setResultData((prevData) => ({
      ...prevData,
      [e.target.name]: formattedDate,
    }));
  };

  const handleTimeChange = (time, type) => {
    if (time != null) {
      const timing = time.toLocaleTimeString();
      setResultData({
        ...ResultData,
        [type === "start" ? "DateFromHours" : "DateEndHours"]: timing,
      });
    }
  };

  useEffect(() => {
    onSnapshot(Cities, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }));

      if (newData.length > 0) {
        setItem(newData[0].data);
      }
    });
  }, [Cities]);

  const handleCityChange = (selectedOptions) => {
    const selectedCities = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setResultData((prevData) => ({
      ...prevData,
      city: selectedCities,
    }));
  };

  useEffect(() => {
    if (items.length > 0 && ResultData) {
      const newOptions = items.map((item) => ({
        value: item,
        label: item,
      }));

      setOptions(newOptions);
      const valueNewOption = newOptions.filter((option) =>
        ResultData.city.includes(option.value)
      );

      setValueOption(valueNewOption);
    }
  }, [items, ResultData]);

  useEffect(() => {
    const itemRef = doc(EventRefrence, dbID);
    const unsubscribeEvent = onSnapshot(itemRef, (eventSnapshot) => {
      if (eventSnapshot.exists()) {
        const eventData = eventSnapshot.data();
        setResultData(eventData);

        // setSelectedTovOptions([...eventData.TransferOfValue]);

        if (eventData.bgColor) {
          setSelectedColor(eventData.bgColor);
        }
        if (eventData.fontColor) {
          setSelectedFont(eventData.fontColor);
        }
        if (eventData.btnColor) {
          setSelectedBtn(eventData.btnColor);
        }

        const userRef = doc(UserRef, eventData.CreatedByID);
        const unsubscribeUser = onSnapshot(userRef, (userSnapshot) => {
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setCreatedBy(userData);
          }

          if (
            currentUserRole.admin === true ||
            currentUsr === eventData.CreatedByID
          ) {
            setCheckAuth(true);
          }
        });

        return () => unsubscribeUser();
      }
    });

    return () => unsubscribeEvent();
  }, [dbID]);

  const updateEvent = async (event) => {
    event.preventDefault();

    try {
      const willUpdate = await swal({
        title: "Are you sure about these changes?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willUpdate) {
        const itemRef = doc(EventRefrence, dbID);

        const eventSnapshot = await getDoc(itemRef);
        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          // eventData.TransferOfValue = selectedTovOptions;

          setResultData(eventData);

          await updateDoc(itemRef, ResultData);
        }
      }
    } catch (error) {
      swal({
        title: "Error",
        text: "There was an issue updating the Event.",
        icon: "error",
      });
    }
  };

  const handleColorChange = (color, type) => {
    setSelectedColor(color.hex);

    setResultData({ ...ResultData, [type]: color.hex });
  };

  const handleClickOutside = (event) => {
    if (bgPickerRef.current && !bgPickerRef.current.contains(event.target)) {
      setShowBgPicker(false);
    }
    if (
      fontPickerRef.current &&
      !fontPickerRef.current.contains(event.target)
    ) {
      setShowFontPicker(false);
    }
    if (btnPickerRef.current && !btnPickerRef.current.contains(event.target)) {
      setShowBtnPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // useEffect(() => {
  //   console.log(ResultData, "ResultData");
  // }, [ResultData]);

  return (
    <div>
      {options.length > 0 && checkAuth && (
        <button
          className="bg-success px-2 py-2.5 rounded text-white mb-2"
          data-bs-toggle="modal"
          data-bs-target="#animation"
          type="button"
        >
          Edit Event{" "}
        </button>
      )}
      <div
        className="modal text-left"
        id="animation"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel6"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel6">
                Edit Event
              </h4>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body ">
              <form onSubmit={updateEvent} className="p-3">
                <div className="grid grid-cols-1 gap-4 my-3">
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      Event Name
                    </label>
                    <input
                      value={ResultData.eventName || ""}
                      onChange={handleInputChange}
                      name={"eventName"}
                      type="text"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline "
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 my-3">
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      BeSure
                    </label>
                    <input
                      value={ResultData.BeSure || ""}
                      onChange={handleInputChange}
                      name={"BeSure"}
                      type="text"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline "
                    />
                  </div>

                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      {ResultData.PO ? <b>PO</b> : <b>Amex Num</b>}
                    </label>
                    <input
                      value={
                        ResultData.PO ? ResultData.PO : ResultData.AmexNum || ""
                      }
                      onChange={handleInputChange}
                      name={ResultData.PO ? "PO" : "AmexNum"}
                      type="text"
                      className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 my-3">
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      Start Date
                    </label>
                    <input
                      value={
                        convertStartDateWithYearFormat(ResultData.eventDate) ||
                        ""
                      }
                      onChange={handleDateChange}
                      name={"eventDate"}
                      onClick={(e) => e.target.showPicker()}
                      type="date"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline  "
                    />
                  </div>
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      End Date
                    </label>
                    <input
                      value={
                        convertStartDateWithYearFormat(ResultData.endDate) || ""
                      }
                      onChange={handleDateChange}
                      onClick={(e) => e.target.showPicker()}
                      name={"endDate"}
                      type="date"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline  "
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 my-3">
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      Start Time
                    </label>
                    <DatePicker
                      value={ResultData.DateFromHours || ""}
                      onChange={(selectedTime) => {
                        handleTimeChange(selectedTime, "start");
                      }}
                      name={"DateFromHours"}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline "
                    />
                  </div>
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      End Time
                    </label>
                    <DatePicker
                      value={ResultData.DateEndHours || ""}
                      onChange={(selectedTime) => {
                        handleTimeChange(selectedTime, "end");
                      }}
                      name={"DateEndHours"}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline "
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 my-3">
                  <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      City
                    </label>
                    <Multiselect
                      options={options}
                      selectedValues={valueOption}
                      onSelect={(selectedOptions) => {
                        handleCityChange(selectedOptions);
                      }}
                      onRemove={(selectedOptions) => {
                        handleCityChange(selectedOptions);
                      }}
                      displayValue="value"
                      placeholder="Select City"
                      showArrow={true}
                      name={"city"}
                    />
                  </div>
                </div>
                <div>
                  <div className=" grid grid-cols-3 gap-4 mt-4 mb-2">
                    <div>
                      <label className="block text-black text-sm font-bold mb-2">
                        Background Color
                      </label>

                      <div className="flex items-center justify-center gap-2 border border-gray-200 rounded w-75 mb-6">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: `${ResultData.bgColor}`,
                            cursor: "pointer",
                            borderRadius: "5px",
                          }}
                          className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                          onClick={() => setShowBgPicker(!showBgPicker)}
                          ref={bgPickerRef}
                        ></div>
                        {showBgPicker && (
                          <div ref={bgPickerRef}>
                            <ChromePicker
                              color={selectedColor}
                              onChange={(color) =>
                                handleColorChange(color, "bgColor")
                              }
                              className="mx-6 my-2"
                            />
                          </div>
                        )}
                        <p className="m-0 p-2">{ResultData.bgColor}</p>
                      </div>
                    </div>
                    <div>
                      <label className="block text-black text-sm font-bold mb-2">
                        Font Color
                      </label>
                      <div className="flex items-center justify-center gap-2 border border-gray-200 rounded w-75 mb-6">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: `${ResultData.fontColor}`,
                            cursor: "pointer",
                            borderRadius: "5px",
                          }}
                          className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                          onClick={() => setShowFontPicker(!showFontPicker)}
                          ref={fontPickerRef}
                        ></div>
                        {showFontPicker && (
                          <div ref={fontPickerRef}>
                            <ChromePicker
                              color={selectedFont}
                              onChange={(color) =>
                                handleColorChange(color, "fontColor")
                              }
                              className="mx-6"
                            />
                          </div>
                        )}
                        <p className="m-0 p-2">{ResultData.fontColor}</p>
                      </div>
                    </div>
                    <div>
                      <label className="block text-black text-sm font-bold mb-2">
                        Button Color
                      </label>
                      <div className="flex items-center justify-center gap-2 border border-gray-200 rounded w-75 mb-6">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: `${ResultData.btnColor}`,
                            cursor: "pointer",
                            borderRadius: "5px",
                          }}
                          className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                          onClick={() => setShowBtnPicker(!showBtnPicker)}
                        ></div>
                        {showBtnPicker && (
                          <div ref={btnPickerRef}>
                            <ChromePicker
                              color={selectedBtn}
                              onChange={(color) =>
                                handleColorChange(color, "btnColor")
                              }
                              className="mx-6"
                            />
                          </div>
                        )}
                        <p className="m-0 p-2">{ResultData.btnColor}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-8 pt-3">
                  <button
                    type="submit"
                    className="bg-blueColor rounded p-2 text-white ms-1"
                    data-bs-dismiss="modal"
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Update Changes</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEvent;

/* <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      Franchise
                    </label>
                    <select
                      disabled
                      value={ResultData.Franchise || ""}
                      onChange={handleInputChange}
                      name={"Franchise"}
                      variant="outlined"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black bg-gray-100 "
                    >
                      <option value="Immunology & Neuroscience">
                        Immunology & Neuroscience
                      </option>

                      <option value="Gtx">Gtx</option>

                      <option value="Oncology">Oncology</option>
                      <option value="Cardiovascular">Cardiovascular</option>
                      <option value="Medical">Medical</option>

                      <option value="Value, Access & KAM">
                        Value, Access & KAM
                      </option>
                      <option value="BE&E">BE&E</option>
                    </select>
                  </div> */

/* <div>
                    <label className="block text-black text-sm font-bold mb-2">
                      Cost per Delegate
                    </label>
                    <input
                      disabled
                      value={FormateCost(
                        ResultData.CostperDelegate,
                        ResultData.EventCurrency
                      )}
                      name={"costPerDelegate"}
                      onChange={handleInputChange}
                      type="text"
                      className=" appearance-none border rounded w-full py-2 px-3 text-black  bg-gray-100 opacity-100"
                    />
                  </div> */
