import { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FireBaseContext } from "../../Context/FireBase";
import swal from "sweetalert";
import { doc, updateDoc } from "firebase/firestore";

export default function UsersSettings({ rowId, row }) {
  const ITEM_HEIGHT = 38;
  const [anchorEl, setAnchorEl] = useState(null);
  const { UserRef } = useContext(FireBaseContext);
  const open = Boolean(anchorEl);
  const [name, setName] = useState(row.Name);
  const [email, setEmail] = useState(row.Email);
  const [franchise, setFranchise] = useState(row.franchiseType);


  const updateUser = async () => {
    const ref = doc(UserRef, row.ID);
    try {
      await updateDoc(ref, {
        Name: name,
        Email: email,
        franchiseType: franchise,
        "Role.franchiseType": franchise,
      });
      swal({
        title: "User data updated successfully!",
        icon: "success",
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const removeSubscriber = async (id) => {
    const ref = doc(UserRef, row.ID);

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: `${row.Email} ${
            row.Condition.Blocked ? "Unblocked" : "Blocked"
          }   `,
          icon: "success",
        });
        await updateDoc(ref, {
          ...row,
          Condition: {
            Blocked: !row.Condition.Blocked,
          },
        });
        handleClose();
      }
    });
  };
  return (
    <div>
  
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "15ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            removeSubscriber(rowId);
          }}
        >
          {row.Condition.Blocked ? (
            <div className="d-flex   justify-content-center align-items-center w-75  gap-2 ">
              <i className="fa-solid fa-lock-open text-success w-25"></i>
              <span className="   w-75 darkBlue">Unblock</span>
            </div>
          ) : (
            <div className="d-flex   justify-content-center align-items-center w-75  gap-2 ">
              <i className="fa-solid fa-user-slash text-danger w-25"></i>
              <span className="   w-75 darkBlue">Block</span>
            </div>
          )}
        </MenuItem>
        <MenuItem>
          <button
            type="button"
            className="flex gap-3 items-center justify-center"
            data-bs-toggle="modal"
            data-bs-target="#animation"
          >
            <i className="fa-solid fa-edit text-success w-50 ml-1 mb-1"></i>
            <span className="w-75 darkBlue">Edit</span>
          </button>
          <div id="animation" className="modal text-left" role="dialog">
            <div
              className="modal-dialog modal-dialog-centered modal-dialog-scrollable "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4>Edit User</h4>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i data-feather="x"></i>
                  </button>
                  <button
                    type="button"
                    className="close "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div className="modal-body ">
                  <form>
                    <div className="w-full">
                      <label className="block text-black text-sm font-bold my-2">
                        Name
                      </label>
                      <input
                        type="text"
                        sx={{ m: 1, width: "25ch" }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-100 appearance-none border rounded  px-3 text-black leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
                      />
                    </div>
                    <div className="w-full">
                      <label className="block text-black text-sm font-bold my-2">
                        Email
                      </label>
                      <input
                        type="email"
                        sx={{ m: 1, width: "25ch" }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-100 appearance-none border rounded  px-3 text-black leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
                      />
                    </div>
                    <div className="w-full">
                      <label className="block text-black text-sm font-bold my-2">
                        Franchise
                      </label>
                      <select
                        type="text"
                        sx={{ m: 1, width: "25ch" }}
                        value={franchise}
                        onChange={(e) => setFranchise(e.target.value)}
                        className="w-100 appearance-none border rounded  px-3 text-black leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200"
                      >
                        <option value={franchise}>{franchise}</option>
                        <option value="Immunology & Neuroscience">Immunology & Neuroscience</option>
                        <option value="Gtx">Gtx</option>
                        <option value="Oncology">Oncology</option>
                        <option value="Cardiovascular">Cardiovascular</option>
                        <option value="Medical">Medical</option>
                        <option value="Value, Access & KAM">
                          Value, Access & KAM
                        </option>
                        <option value="BE&E">BE&E</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="bg-blueColor text-white rounded p-2 "
                    data-bs-dismiss="modal"
                    onClick={updateUser}
                  >
                    <i className="bx bx-check d-block d-sm-none"></i>
                    <span className="d-none d-sm-block">Update Changes</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MenuItem>

      </Menu>
    </div>
  );
}






