import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "@mui/material/styles";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { RootLayout } from "./Components/RootLayOut/RootLayout";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import { Events } from "./Pages/Events/Events";
import { Profile } from "./Pages/Profile/Profile";
import { Teams } from "./Pages/Teams/Teams";
import { MainContent } from "./Components/MainContent/MainContent";
import { Suspense, lazy } from "react";
import lazyImg from "./assets/LoadingLogo.png";
import SearchContextProvider from "./Context/SearchContext.js";
import { AddNewEvent } from "./Pages/AddNewEvent/AddNewEvent.jsx";
import FireBaseContextProvider from "./Context/FireBase.js";
import { Subscribers } from "./Pages/Subscriber/Subscribers.jsx";
import UpdateSubScriber from "./Pages/UpdateSubScriber/UpdateSubScriber.jsx";
import { DeletedData } from "./Pages/DeletedData/DeletedData.jsx";
import { Protected } from "./Components/ProtectedRouted/Protected.jsx";
import { MyEvents } from "./Pages/MyEvents/MyEvents.jsx";
import { DeletedSubscribers } from "./Pages/DeletedData/DeletedSubscribers/DeletedSubscribers.jsx";
import { DeletedEvents } from "./Pages/DeletedData/DeletedElements/DeletedEvents.jsx";
import { AllUsers } from "./Pages/AllUsers/AllUsers.jsx";
import { Users } from "./Pages/Users/Users";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute.jsx";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword.jsx";
import ResetPassword from "./Components/ResetPassword/ResetPassword.jsx";
import HelperContextProvider from "./Context/HelperContext.js";

async function delayForDemo(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 4000);
  }).then(() => promise);
}

let LoginLazy = lazy(() => delayForDemo(import("./Pages/Login/Login")));

const RouterStructure = () => {
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route path="/" element={<App />}>
            {/* Public Routes */}
            <Route path="/" element={<LoginLazy />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />

            {/* Protected Routes */}
            <Route
              path="/app"
              element={
                <Protected>
                  <RootLayout />
                </Protected>
              }
            >
              <Route path="/app" element={<MainContent />}>
                <Route index element={<Dashboard />} />
                <Route path="events" element={<Events />} />
                <Route path="Profile" element={<Profile />} />
                <Route path="AddEvents" element={<AddNewEvent />} />
                <Route path="MyEvents" element={<MyEvents />} />

                {/* Nested Routes */}
                <Route path="subscribers/:id/:dbID">
                  <Route index element={<Subscribers />} />
                  <Route
                    path="UpdateSubscriber/:userId/:UserDbID"
                    element={<UpdateSubScriber />}
                  />
                </Route>
                <Route path="DeletedData" element={<DeletedData />}>
                  <Route index element={<DeletedEvents />} />
                  <Route path="events" element={<DeletedEvents />} />
                  <Route path="Subscribers" element={<DeletedSubscribers />} />
                </Route>

                {/* Routes with Private Access */}
                <Route
                  path="teams"
                  element={
                    <PrivateRoute>
                      <Teams />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="AllUsers"
                  element={
                    <PrivateRoute>
                      <AllUsers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="CreateUser"
                  element={
                    <PrivateRoute>
                      <Users />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Route>
          </Route>
        )
      )}
    />
  );
};

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [process.env.REACT_APP_URL, /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  window.onerror = (message, error) => {
    Sentry.captureException(error || new Error(message));
  };

  // Capture unhandled promise rejections
  window.onunhandledrejection = (event) => {
    Sentry.captureException(event.reason);
  };
}



// const root = ReactDOM.createRoot(document.getElementById("root"));
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

// root.render(
//   <HelperContextProvider>
//     <FireBaseContextProvider>
//       <SearchContextProvider>
//         <Suspense
//           children={
//             <div className="vh-100  w-100 d-flex justify-content-center align-items-center">
//               <div className="lazy-loadingImg w-75">
//                 <img src={lazyImg} alt="LazyLoadingImg" width={"100%"} />
//               </div>
//             </div>
//           }
//           fallback={
//             <div className="vh-100  w-100 d-flex justify-content-center align-items-center">
//               <div className="lazy-loadingImg w-75">
//                 <img src={lazyImg} alt="LazyLoadingImg" width={"100%"} />
//               </div>
//             </div>
//           }
//         >
//           <ErrorBoundary fallback={<p>Something went wrong.</p>}>
//             <RouterStructure />
//           </ErrorBoundary>

//           <RouterStructure />
//           {/* <RouterProvider router={router()} /> */}
//         </Suspense>
//       </SearchContextProvider>
//     </FireBaseContextProvider>
//   </HelperContextProvider>
// );

root.render(
  <HelperContextProvider>
    <FireBaseContextProvider>
      <SearchContextProvider>
        <Suspense
          fallback={
            <div className="vh-100 w-100 d-flex justify-content-center align-items-center">
              <div className="lazy-loadingImg w-75">
                <img src={lazyImg} alt="LazyLoadingImg" width={"100%"} />
              </div>
            </div>
          }
        >
          <ErrorBoundary fallback={<p>Something went wrong.</p>}>
            <RouterStructure />
          </ErrorBoundary>
        </Suspense>
      </SearchContextProvider>
    </FireBaseContextProvider>
  </HelperContextProvider>
);
reportWebVitals();
